<template>
  <!-- Sobre Nós -->
  <div class="container" id="sobre">
    <div class="titulo titulo_sobre">
      <h1>{{ $t('message.sobre') }}</h1>
      <img src="./../../public/imgs/line.png" alt="efeito" />
    </div>

    <div class="section_sobre">
      <div class="sobre_txt">
        <p class="sobre_paragrafo">
          {{ $t('message.sobre_paragrafo') }}
        </p>
      </div>
      <div class="sobre_img">
        <img src="./../../public/imgs/logo_sobre.png" />
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'SobreNosComponent'
}
</script>

<style scoped>
/* Sobre nós  */
.titulo_sobre {
  text-align: start;
}

.titulo_sobre h1 {
  text-align: start;

}

.titulo_sobre img {
  width: 18rem;
  margin-left: -1rem;
}

.section_sobre {
  display: flex;
  justify-content: space-between;
}

.sobre_txt {
  width: 40rem;
}

.sobre_paragrafo {
  color: var(--White, #FFF);
  font-family: Open Sans;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sobre_img img {
  width: 20rem;
}

@media (max-width: 760px) {
  .section_sobre {
    flex-direction: column;
  }

  .sobre_txt {
    width: auto;
  }

  .sobre_img {
    text-align: center;
  }
}
</style>
