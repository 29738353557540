<template>
  <div id="servico">
    <div class="container">
      <div class="titulo_servico">
        <div class="flex">
          <h1>{{ $t('message.nossos_servicos') }}</h1>
        </div>
        <img class="img_line" src="./../../public/imgs/line.png" alt="efeito" />
      </div>

      <div class="row">
        <div class="col center">
          <div class="card clickable b_roxo" @click="abrirPopup($t('message.midia_performace_paragrafo_full'), 'b_roxo')">
            <div class="iframe_card card_roxo">
              <img src="./../../public/imgs/performace.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.midia_performace') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.midia_performace_paragrafo') }}
            </p>
          </div>
        </div>
        <div class="col center">
          <div class="card clickable b_rosa"
            @click="abrirPopup($t('message.midia_programatica_paragrafo_full'), 'b_rosa')">
            <div class="iframe_card card_rosa">
              <img src="./../../public/imgs/midia_programatica.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.midia_programatica') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.midia_programatica_paragrafo') }}
            </p>
          </div>
        </div>
        <div class="col center">
          <div class="card clickable b_vermelho"
            @click="abrirPopup($t('message.game_advertising_paragrafo_full'), 'b_vermelho')">
            <div class="iframe_card card_vermelho">
              <img src="./../../public/imgs/game_ad.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.game_advertising') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.game_advertising_paragrafo') }}
            </p>
          </div>
        </div>
        <div class="col center">
          <div class="card clickable b_azul"
            @click="abrirPopup($t('message.desenvolvimento_jogos_paragrafo_full'), 'b_azul')">
            <div class="iframe_card card_azul">
              <img src="./../../public/imgs/desenvolvimento_games.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.desenvolvimento_jogos') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.desenvolvimento_jogos_paragrafo') }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col center">
          <div class="card clickable b_azul" @click="abrirPopup($t('message.gamificacao_paragrafo_full'), 'b_azul')">
            <div class="iframe_card card_azul">
              <img src="./../../public/imgs/gamificacao.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.gamificacao') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.gamificacao_paragrafo') }}
            </p>
          </div>
        </div>
        <div class="col center">
          <div class="card clickable b_roxo" @click="abrirPopup($t('message.metaverso_paragrafo_full'), 'b_roxo')">
            <div class="iframe_card card_roxo">
              <img src="./../../public/imgs/metaverso.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.metaverso') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.metaverso_paragrafo') }}
            </p>
          </div>
        </div>
        <div class="col center">
          <div class="card clickable b_rosa" @click="abrirPopup($t('message.influenciador_paragrafo_full'), 'b_rosa')">
            <div class="iframe_card card_rosa">
              <img src="./../../public/imgs/influenciador.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.influenciador') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.influenciador_paragrafo') }}
            </p>
          </div>
        </div>
        <div class="col center">
          <div class="card clickable b_vermelho" @click="abrirPopup($t('message.tv_paragrafo_full'), 'b_vermelho')">
            <div class="iframe_card card_vermelho">
              <img src="./../../public/imgs/tv.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.tv') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.tv_paragrafo') }}
            </p>
          </div>
        </div>
      </div>

      <div class="row center">
        <div class="col center">
          <div class="card clickable b_roxo" @click="abrirPopup($t('message.ooh_paragrafo_full'), 'b_roxo')">
            <div class="iframe_card card_roxo">
              <img src="./../../public/imgs/ooh.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.ooh') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.ooh_paragrafo') }}
            </p>
          </div>
        </div>
        <div class="col center">
          <div class="card clickable b_azul" @click="abrirPopup($t('message.esport_paragrafo_full'), 'b_azul')">
            <div class="iframe_card card_azul">
              <img src="./../../public/imgs/esport.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.esport') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.esport_paragrafo') }}
            </p>
          </div>
        </div>
        <div class="col center">
          <div class="card clickable b_vermelho"
            @click="abrirPopup($t('message.licenciamento_paragrafo_full'), 'b_vermelho')">
            <div class="iframe_card card_vermelho">
              <img src="./../../public/imgs/licenciamento.png" alt="icon" />
            </div>
            <h6 class="titulo_card">
              {{ $t('message.licenciamento') }}
            </h6>
            <p class="paragrafo_card">
              {{ $t('message.licenciamento_paragrafo') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card_popup" v-if="exibirPopup" :class="['popup', classePopup]">
    <p class="paragrafo_card txt_maior" v-html="popupTxt"></p>
    <button class="btn_fechar" @click="abrirPopup" v> {{ $t('message.fechar') }}</button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'ServicoComponent',
  data() {
    return {
      exibirPopup: false,
      popupTxt: '',
      classePopup: '',
      currentArrow: 0,
    }
  },
  mounted() {
    // Inicia a rotação automática
    setInterval(() => {
      this.currentArrow = (this.currentArrow + 1) % 3;
    }, 1000); // Ajuste o intervalo conforme necessário (em milissegundos)
  },
  methods: {
    abrirPopup(v, css) {
      this.classePopup = css
      this.popupTxt = v
      this.exibirPopup = !this.exibirPopup
    },
    showArrow(arrowIndex) {
      return arrowIndex === this.currentArrow;
    },
  }
}

</script>

<style scoped>
.titulo_servico h1 {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 55px;
  font-style: italic;
  font-weight: 900;
  /* line-height: 1.5rem; */
}

.titulo_servico {
  padding-top: 0.2rem;
  margin-top: 1rem;
  text-align: center;
}


.flex {
  display: flex;
  justify-content: center;
  height: 4rem;
}

.img_seta img {
  height: 6rem;
}

/* Cards */
.card {
  border-radius: 16px;
  display: flex;
  width: 269px;
  padding: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.iframe_card {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin: 1rem;
}

.titulo_card {
  display: flex;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: var(--White, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 23px;
  font-style: italic;
  font-weight: 700;
  line-height: 25px;
  padding: 0 1rem;
}

.paragrafo_card {
  margin: 0 1rem;
  margin-bottom: 1rem;
  text-align: start;
  color: var(--White, #FFF);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.b_roxo {
  border: 2px solid var(--Primary, #6E43EA);
  background: linear-gradient(238deg, rgba(110, 67, 234, 0.20) 29.27%, rgba(55, 10, 185, 0.03) 99.58%);
}

.card_roxo {
  border-radius: 8px;
  border: 1px solid var(--Primary-Light, #AE91FF);
  background: linear-gradient(68deg, #402C7C 7.92%, rgba(98, 56, 222, 0.14) 82.91%, rgba(98, 56, 224, 0.12) 82.92%);
}

.b_rosa {
  border: 2px solid #B243EA;
  background: linear-gradient(238deg, rgba(178, 67, 234, 0.20) 29.27%, rgba(127, 10, 185, 0.03) 99.58%);
}

.card_rosa {
  border-radius: 8px;
  border: 1px solid #DA91FF;
  background: linear-gradient(68deg, #612C7C 7.92%, rgba(168, 56, 224, 0.12) 84.48%);
}

.b_vermelho {
  border-radius: 16px;
  border: 2px solid #EA439D;
  background: linear-gradient(238deg, rgba(234, 67, 156, 0.20) 29.27%, rgba(185, 10, 103, 0.03) 99.58%);
}

.card_vermelho {
  border-radius: 8px;
  border: 1px solid #FF91CC;
  background: linear-gradient(68deg, #7C2C57 7.92%, rgba(224, 56, 146, 0.12) 84.48%);
}

.b_azul {
  border-radius: 16px;
  border: 2px solid #4386EA;
  background: linear-gradient(238deg, rgba(67, 134, 234, 0.20) 29.27%, rgba(10, 80, 185, 0.03) 99.58%);
}

.card_azul {
  border-radius: 8px;
  border: 1px solid #91BDFF;
  background: linear-gradient(68deg, #2C4C7C 7.92%, rgba(56, 123, 224, 0.12) 84.48%);
}

/* FIM CARD */

/* Pop up  */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  padding: 2rem;
  z-index: 1000;

  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.785);
}

.btn_fechar {
  border-radius: 16px;
  border: 1px solid var(--Light--1, #F6F5F6);
  background: var(--White, #FFF);
  border: 2px solid #ea4343;
  background: linear-gradient(238deg, rgba(234, 67, 67, 0.2) 29.27%, rgba(185, 10, 103, 0.03) 99.58%);
  padding: 0.7rem 2rem;
  margin-top: 1rem;
}

.txt_maior {
  font-size: 1.6rem;
}

.txt_maior {
  font-size: 1.6rem;
}

@media (max-width: 900px) {
  .popup {
    width: 70vw;
  }

  .txt_maior {
    font-size: 1.4rem;
  }
}

@media (max-width: 800px) {
  .popup {
    padding: 0 0.5rem;
    margin: 0.2rem 0.7rem;
    width: 75vw;
  }

  .txt_maior {
    font-size: 1.3rem;
  }
}

@media (max-width: 700px) {
  .popup {
    padding: 0 0.5rem;
    margin: 0.2rem 0.7rem;
    width: 80vw;
  }
}

@media (max-width: 600px) {
  .popup {
    padding: 0 0.5rem;
    margin: 0.2rem 0.7rem;
    width: 90vw;
  }

  .txt_maior {
    padding: 0;
    margin: 0.2rem 0.7rem;
    font-size: 1rem;
  }

  .img_line {
    width: 80vw;
  }

  .titulo h1 {
    line-height: 3rem;
  }
}

@media (max-width: 500px) {
  .titulo_servico h1 {
    font-size: 11vw;
  }
}

.card_popup {
  margin: 1rem 0;
  padding: 0.7rem 0;
}

/* Fim pop up */
</style>
