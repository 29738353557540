<template>
  <div class="banner">
    <div class="efeito_1">
      <img src="./../../public/imgs/efeito_1.png" />
    </div>
    <div class="efeito_2">
      <img src="./../../public/imgs/efeito_2.png" />
    </div>
    <div class="efeito_3">
      <img src="./../../public/imgs/losango.png" />
    </div>
    <div class="efeito_4">
      <img src="./../../public/imgs/efeito_4.png" />
    </div>
    <div class="efeito_5">
      <div class="img_seta">
        <img src="./../../public/imgs/seta.gif" />
      </div>
    </div>
    <div class="video-container">
      <video ref="myVideo" autoplay muted loop playsinline start="30">
        <source src="https://layout.hoomweb.com.br/video/Paradise.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'BannerComponent',
  mounted() {
    this.$refs.myVideo.addEventListener('loadedmetadata', () => {
      this.$refs.myVideo.currentTime = 30 // Defina o tempo desejado em segundos
      this.$refs.myVideo.play() // Inicie o vídeo
    })
  }
}
</script>

<style scoped>
.efeito_1 {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 7rem;
}

.efeito_2 {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 7rem;
}

.efeito_3 {
  position: absolute;
  z-index: 2;
  left: 0;
}

.efeito_3 img {
  width: 67vw;
  height: 85vh;

}

.efeito_4 {
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 9rem;
  /* background: #ffffff45;
  background: linear-gradient(to bottom, rgba(14, 14, 14, 0.795) 29.27%, rgba(14, 14, 14, 0.030) 99.58%); */
}

.efeito_4 img {
  width: 100vw;
  height: 9rem;
}

.efeito_5 {
  position: absolute;
  z-index: 2;
  width: 100vw;
  /* height: 9rem; */
  bottom: -3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner {
  position: relative;
  z-index: 1;
  height: 85vh;
  overflow: hidden;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-container video::before {
  content: url('./../../public/imgs/bg_home.png');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
