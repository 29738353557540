<template>
  <div class="navbar_personalizado_all ">
    <div class="idioma mt-2">
      <div>
        <a @click="changeLanguage('pt')" class="clickable">
          <img class="img_idioma" src="./../../public/imgs/pt.png" alt="logo" />
        </a>
      </div>
      <div>
        <a @click="changeLanguage('en')" class="clickable">
          <img class="img_idioma" src="./../../public/imgs/en.png" alt="pt" />
        </a>
      </div>
    </div>
    <div class="header__nav">
      <nav class="navbar navbar_personalizado navbar-expand-lg navbar-light static-top">
        <div class="logo_mobile desktop_none">
          <input type="checkbox" id="checkbox4" class="checkbox4 visuallyHidden navbar-toggler btn_menu"
            data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
            aria-label="Toggle navigation">
          <label for="checkbox4">
            <div class="hamburger hamburger4">
              <span class="bar bar1"></span>
              <span class="bar bar2"></span>
              <span class="bar bar3"></span>
              <span class="bar bar4"></span>
              <span class="bar bar5"></span>
            </div>
          </label>
          <div class="desktop_none">
            <a href="/" class="link clickable">
              <img class="logo_png desktop_none" src="./../../public/imgs/logo.png" alt="logo" />
            </a>
          </div>
        </div>
        <div class="nav__site__menu collapse navbar-collapse" id="navbarResponsive">
          <div class="itens_menu_header">
            <ul class="navbar-nav nav_bg">
              <li class="nav-item nav_titulo">
                <a href="/" class="nav-link">
                  <p class="titulo_header">{{ $t('message.inicio') }}</p>
                </a>
              </li>
              <li class="nav-item nav_titulo">
                <a class="nav-link" href="/#servico">
                  <p class="titulo_header">{{ $t('message.servico') }}</p>
                </a>
              </li>
              <li class="nav-item nav_titulo">
                <a href="/" class="link">
                  <img class="logo_png mobile_none" src="./../../public/imgs/logo.png" alt="logo" />
                </a>
              </li>
              <li class="nav-item nav_titulo">
                <a class="nav-link" href="/#sobre">
                  <p class="titulo_header">{{ $t('message.sobre') }} </p>
                </a>
              </li>
              <li class="nav-item nav_titulo">
                <a class="nav-link" href="/#contato">
                  <p class="titulo_header">{{ $t('message.contato') }}</p>
                </a>
              </li>
              <li class="nav-item nav_titulo desktop_none">
                <div class="idioma_mobile ma-2">
                  <div>
                    <a @click="changeLanguage('pt')" class="clickable">
                      <img class="img_idioma" src="./../../public/imgs/pt.png" alt="logo" />
                    </a>
                  </div>
                  <div>
                    <a @click="changeLanguage('en')" class="clickable">
                      <img class="img_idioma" src="./../../public/imgs/en.png" alt="pt" />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'HeaderComponent',
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale
    },
  }
}
</script>

<style scoped>
.desktop_none {
  display: none;
}

.navbar_personalizado_all {
  position: absolute;
  width: 100vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.idioma {
  display: flex;
  width: 1110px;
  height: 1.5rem;
  /* background-color: #fa9500; */
  justify-content: flex-end;
}

.img_idioma {
  margin: 0rem 0.5rem;
}

.navbar_personalizado {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  /* background-color: #2c2860; */
}

.titulo_header {
  color: var(--White, #FFF);
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.logo_png {
  margin-top: 15px;
  margin-right: 30px;
  width: 150px;
}

.navbar-nav {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 82px;
  width: 1110px;
  padding: 0;
  /* background-color: #fa9500; */
}

@media (max-width: 1130px) {

  .navbar-nav,
  .idioma {
    /* background-color: red; */
    width: 980px;
  }
}

@media screen and (orientation: portrait),
(max-width: 1000px) {

  .navbar_personalizado {
    width: 100vw;
    position: fixed;
    right: 0;
  }

  .idioma {
    display: none;
  }

  .mobile_none {
    display: none;
  }

  .desktop_none {
    display: block;
  }

  .logo_mobile {
    width: 41vw;
  }

  .navbar_personalizado {
    /* background-color: red; */
    justify-content: space-around;
    align-items: center;
    max-width: 1120px;
    height: 100px;
  }

  .logo_mobile,
  .menu {
    width: 58vw;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .navbar-nav {
    align-items: center;
  }

  .navbar_personalizado {
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.459);
    z-index: 90000;
  }

  .nav_bg {
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 2;
    background-color: black;
    height: auto;
    border-radius: 1rem;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .idioma_mobile {
    display: flex;
  }
}

.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.hamburger {
  margin: 0 auto;
  margin-top: 30px;
  width: 30px;
  height: 30px;
  position: relative;
}

.hamburger .bar {
  padding: 0;
  width: 30px;
  height: 4px;
  background-color: #fff;
  display: block;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  position: absolute;
}

.bar1 {
  top: 0;
}

.bar2,
.bar3 {
  top: 13.5px;
}

.bar3 {
  right: 0;
}

.bar4 {
  bottom: 0;
}

.hamburger2 .bar2,
.hamburger3 .bar3,
.hamburger4 .bar4 {
  top: 13.5px;
}

.hamburger4 .bar5 {
  bottom: 0px;
}

.hamburger4 .bar {
  transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
}

.hamburger4 .bar2 {
  width: 1px;
  transform: rotate(90deg);
  left: 13.5px;
}

.hamburger4 .bar3 {
  width: 1px;
  left: 13.5px;
}

.checkbox4:checked+label>.hamburger4>.bar1 {
  top: 13.5px;
  background-color: transparent;
}

.checkbox4:checked+label>.hamburger4>.bar2 {
  left: 0px;
  width: 30px;
  transform: rotate(45deg);
}

.checkbox4:checked+label>.hamburger4>.bar3 {
  left: 0;
  width: 30px;
  transform: rotate(-45deg);
}

.checkbox4:checked+label>.hamburger4>.bar4 {
  background-color: transparent;
}

.checkbox4:checked+label>.hamburger4>.bar5 {
  bottom: 13.5px;
  background-color: transparent;
}
</style>
