<template>
  <div>
    <HeaderComponent />
    <router-view/>
    <FooterComponent />
  </div>
</template>

<script>
/* eslint-disable */ 
import HeaderComponent from '../src/components/HeaderComponent.vue'
import FooterComponent from '../src/components/FooterComponent.vue'
export default {
  components: {
    HeaderComponent,
    FooterComponent
  },
  mounted() {
    window.location.href = 'https://bgamification.com/';
  }
}
</script>
<style>
</style>
