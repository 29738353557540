import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createVuetify } from 'vuetify'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

const app = createApp(App)

app.config.globalProperties.$axios = axios

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(createVuetify())
  .mount('#app')
