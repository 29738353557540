<template>
  <Banner />
  <Servico />
  <Clientes />
  <SobreNos />

  <!-- Contato -->
  <div class="container" id="contato">
    <div class="section_contato">
      <div class="contato_img">
        <img src="./../../public/imgs/contact.png" width="608" alt="efeito" />
      </div>
      <div class="contato_txt">
        <div class="row">
          <div class="titulo titulo_contato">
            <h1>{{ $t('message.contato') }}</h1>
            <img src="./../../public/imgs/line.png" alt="efeito" />
          </div>
        </div>
        <div class="row">
          <p class="contato_paragrafo">
            {{ $t('message.entre_contato') }}
          </p>
        </div>
        <div class="form">
          <div class="row">
            <div class="col ">
              <label for="name">{{ $t('message.seu_nome') }}</label>
              <input id="name" v-model="name" required :placeholder="$t('message.exemplo') + $t('message.input_name')"
                class="white-text" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="email">E-mail</label>
              <input id="email" class="white-text" type="text"
                :placeholder="$t('message.exemplo') + ' josesilva@gmail.com'" v-model="email" @input="validateEmail"
                :class="{ 'error': !isEmailValid && email.length > 0 }" />
              <span v-if="!isEmailValid && email.length > 0" class="error">{{ $t('message.email_invalido')
              }}</span>
            </div>

            <div class="col ">
              <label for="phone">{{ $t('message.phone') }}</label>
              <input v-model="phone" :placeholder="$t('message.input_phone')" @input="applyMask" class="white-text" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label>{{ $t('message.escreva_mensagem') }}</label>
              <textarea v-model="body" rows="3" class=" white-text"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn_enviar" type="submit" :class="{ 'disabled': loading }" @click.prevent="emailContato()">
                <p class="enviar">{{ loading ? $t('message.enviando') : $t('message.enviar_mensagem') }}</p>
                <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/BannerComponent.vue'
import Servico from '@/components/ServicoComponent.vue'
import Clientes from '@/components/ClientesComponent.vue'
import SobreNos from '@/components/SobreNosComponent.vue'

/* eslint-disable */
import * as EmailValidator from 'email-validator';
export default {
  name: 'HomeView',
  components: {
    Banner,
    Servico,
    Clientes,
    SobreNos,
    EmailValidator
  },
  data() {
    return {
      email: null,
      name: null,
      phone: null,
      body: null,
      loading: false,
      maxDigits: 11,
      isEmailValid: true,
      csrfToken: null
    };
  },
  methods: {
    async emailContato() {
      if (this.loading == false) {
        this.loading = true;
        if (!this.name || !this.phone || !this.body) {
          if (this.email) {
            if (!this.validateEmail(this.email)) {
              alert(
                this.$t('message.errorValidInputs')
              );
              this.loading = false;
              return;
            }
          }
          alert(
            this.$t('message.errorValidInputs')
          );
          this.loading = false;
          return;
        }

        // const emailBody = `Nome: ${params.name}, E-mail ${params.email}, Telefone: ${params.phone}, Mensagem: ${params.body}`;

        // comercial@bgamification.com
        // partners@bgamification.com

        try {
          const response = await this.$axios.get('https://bgamification.com.br/enviar_email', {
            params: {
              name: this.name,
              email: this.email,
              phone: this.phone,
              body: this.body,
            },
            mode: 'no-cors',
          });
          this.loading = false;
          console.log('Response >> ', response);
          console.log(response.data);
          alert(this.$t('message.sucesso'));
        } catch (error) {
          console.log('Erro na solicitação GET:', error);
          this.loading = false;
          console.log(error);
          alert(this.$t('message.error'));
        }
        this.loading = false;
      }
    },
    validateEmail() {
      this.isEmailValid = EmailValidator.validate(this.email);
    },
    applyMask(event) {
      // const inputValue = event.target.value;
      // const numbersOnly = inputValue.replace(/[^\d]/g, '');
      // const limitedValue = numbersOnly.slice(0, this.maxDigits);
      // const formattedValue = this.formatPhone(limitedValue);
      // this.phone = formattedValue;

    },
    formatPhone(value) {
      return;
      // if (value.length <= 2) {
      //   return `(${value}`;
      // } else if (value.length <= 8) {
      //   return `(${value.slice(0, 2)}) ${value.slice(2)}`;
      // } else {
      //   return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7)}`;
      // }
    },
  },

}
</script>
<style scoped>
/* Contato init  */

.fa-circle-notch {
  font-size: 1.5em;
  margin-left: 5px;
}

.btn_enviar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--Light--1, #F6F5F6);
  background: var(--White, #FFF);
  color: black;

}

.enviar {
  margin: 0.6rem;
  color: var(--Black, #000);
  text-align: center;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.error {
  color: red;
}

input {
  padding: 0.5rem;
}

label {
  margin-top: -0.3rem;
}

.white-text {
  color: white;
  border: 1px solid white;
  border-radius: 0.5rem;
}

.white-text::placeholder {
  color: white;
}

/* Contato  */

.titulo_contato {
  text-align: start;
  height: 3rem;
}

.titulo_contato h1 {
  text-align: start;

}

.titulo_contato img {
  width: 15rem;
  margin-left: -0.5rem;
}

.section_contato {
  display: flex;
  justify-content: space-between;
}

.contato_txt {
  width: 40rem;
}

.contato_paragrafo {
  color: var(--White, #FFF);
  font-family: Open Sans;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 1rem;
}

.contato_img img {
  margin-left: -4rem;
  margin-right: 2rem;
  margin-top: 2rem;
}

@media (max-width: 800px) {
  .contato_txt {
    width: auto;
    padding: 0 1rem;
  }

  .contato_img img {
    margin: 0;
    width: 90vw;
  }

  .section_contato {
    flex-direction: column-reverse;
    align-items: center;
  }

}

/* Contato end  */
</style>